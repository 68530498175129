import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Formik, Form, yupToFormErrors } from 'formik';
import { Checkbox, CircularProgress, Divider, List, ListItem, ListItemIcon, ListItemText, Snackbar, Step, StepButton, StepIcon, StepLabel, Stepper, TextField as Upload } from '@material-ui/core';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import Textfield from '../Components/FormsUI/Textfield';
import SelectDistrict from '../Components/FormsUI/SelectDistrict';
import Select from '../Components/FormsUI/Select';
import DateTimePicker from '../Components/FormsUI/DataTimePicker';
import { fatheroccupationdata, motheroccupationdata, autismData, bloodData, genederData, quotaData, religionData, examNameData, gradeData, boardData, examGroupData } from '../data/data';
import { Link, useHistory } from "react-router-dom";
import useReactRouter from 'use-react-router';
import { Button as Submit } from '@material-ui/core';
import { Button as Buttonh } from '@material-ui/core';
import { green, purple } from "@material-ui/core/colors";
import { TextField as TextF, MenuItem } from '@material-ui/core';
import $ from 'jquery';
import AppMenu from '../Components/Header/appmenu';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(8),
  },
  root: {
    color: green[900],
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)" // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C4C4C4" // customized
      }
    }
  }
}));






const ApplicationForm = () => {

  const info = JSON.parse(sessionStorage.getItem('info'));
  const subinfo = JSON.parse(sessionStorage.getItem('subinfo'));

  const history = useHistory();

  if (info === null) {
    history.push("/");
  }

  const INITIAL_FORM_STATE = {
    studentName: info?.studentName,
    fatherName: info?.fatherName,
    motherName: info?.motherName,

    studentNameBangla: info?.studentNameBangla,
    fatherNameBangla: info?.fatherNameBangla,
    motherNameBangla: info?.motherNameBangla,


    guardianMobile: info?.guardianMobile,
    dateOfBirth: info?.studentDOB,
    religion: info?.studentReligion,
    gender: info?.studentGender,
    bloodGroup: info?.bloodGroup,
    quota: '',
    birthCertificatNo: '',
    nationality: '',
    autism: '',
    fatherMobile: '',
    fatherOccupation: '',
    fatherIncome: '',
    fatherNid: '',

    motherMobile: '',
    motherNid: '',
    motherOccupation: '',
    motherIncome: '',

    presentVillage: '',
    presentPostOffice: '',

    permanentVillege: '',
    permanentPostOffice: '',
    permanentThanaId: '',

    localGuardianName: '',
    examName: '',
    passingYear: '',
    registrationNo: '',
    examRoll: '',
    examGroup: '',
    grade: '',
    gpa: '',
    gpaWithout4Th: '',
    board: '',
    presentDistrict: '',
    presentThanaId: '',
    permanentDistrict: '',
    permanentThanaId: '',
  };

  const FORM_VALIDATION = Yup.object().shape({
    studentName: Yup.string()
      .required('Required'),
    fatherName: Yup.string()
      .required('Required'),
    motherName: Yup.string()
      .required('Required'),
    guardianMobile: Yup.string()
      .required('Required'),
    religion: Yup.string()
      .required('Required'),
    gender: Yup.string()
      .required('Required'),
    // bloodGroup: Yup.string()
    //   .required('Required').nullable(),
    dateOfBirth: Yup.string()
      .required('Required').nullable(),
    // quota: Yup.string()
    //   .required('Required').nullable(),
    // birthCertificatNo: Yup.string()
    //   .required('Required'),
    // nationality: Yup.string()
    //   .required('Required'),
    // autism: Yup.string()
    //   .required('Required'),
    // fatherMobile: Yup.string()
    //   .required('Required'),
    // fatherOccupation: Yup.string()
    //   .required('Required'),
    // fatherIncome: Yup.string()
    //   .required('Required'),
    motherName: Yup.string()
      .required('Required'),
    // motherMobile: Yup.string()
    //   .required('Required'),
    // motherNid: Yup.string()
    //   .required('Required'),
    // fatherNid: Yup.string()
    //   .required('Required'),
    // motherOccupation: Yup.string()
    //   .required('Required'),
    // motherIncome: Yup.string()
    //   .required('Required'),
    // localGuardianName: Yup.string()
    //   .required('Required'),
    /**********************/
    presentVillege: Yup.string(),
    presentPostOffice: Yup.string()
      .required('Required'),

    permanentPostOffice: Yup.string()
      .required('Required'),
    permanentVillege: Yup.string(),

    // examName: Yup.string()
    //   .required('Required'),
    // passingYear: Yup.string()
    //   .required('Required'),
    // registrationNo: Yup.string()
    //   .required('Required'),
    // examRoll: Yup.string()
    //   .required('Required'),
    // examGroup: Yup.string()
    //   .required('Required'),
    // grade: Yup.string()
    //   .required('Required'),
    // gpa: Yup.string()
    //   .required('Required'),
    // gpaWithout4Th: Yup.string()
    //   .required('Required'),
    // board: Yup.string()
    //   .required('Required'),

    // presentDistrict: Yup.string()
    //   .required('Required'),
    // presentThanaId: Yup.string()
    //   .required('Required'),
    // permanentDistrict: Yup.string()
    //   .required('Required'),
    // permanentThanaId: Yup.string()
    //   .required('Required'),

  });



  const classes = useStyles();
  const { match } = useReactRouter();


  const [payId, setpayId] = useState();
  const [successPay, setsuccessPay] = useState(false);
  const [step, setStep] = useState(1);



  const [imageFileContent, setimageFileContent] = useState();
  const [imageFileSave, setimageFileSave] = useState('');
  const [imageFileName, setimageFileName] = useState(undefined);

  function clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch (ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }
  const imageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file?.size > 600000) {
      // file.target.value = null;
      clearFileInput(document.getElementById("imgfile"));
      setimageFileSave(false);
      setimageFileContent('');
      setimageFileName(undefined);
      alert("Image size should be less than 600KB");
      return;
    };
    if (reader !== undefined && file !== undefined) {
      setimageFileSave(true);

      reader.onloadend = () => {
        // setFile(file)
        // setSize(file.size);
        // setName(file.name)
        // setImagePreview(reader.result)
        setimageFileName(file.name)
        setimageFileContent(reader.result.split(',')[1])
      }
      reader.readAsDataURL(file);
    }
  };

  const validateOthers = () => {
    // console.log('sss')
  }

  const clearothers = () => {
    document.getElementById("imgfile").value = null
  }

  const [districtList, setdistrictList] = useState([]);
  const [thanaList, setPresentthanaList] = useState([]);
  const [permanentThanaList, setPermanentThanaList] = useState([]);
  const [presentThanaId, setPresentThanaId] = useState('');
  const [permanentThanaId, setPermanentThanaId] = useState('');
  const [presentDisId, setPresentDisId] = useState('');
  const [permanentDisId, setPermanentDISId] = useState('');
  useEffect(() => {

    async function fetchMyAPI() {
      let disReponse = await fetch('https://api.shebashikkha.com/location/info/district-list')
      disReponse = await disReponse.json()
      //  console.log(disReponse)
      setdistrictList(disReponse?.item)
    }

    async function fetchThana() {
      if (info.districtId != null) {
        let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${info.districtId}`)
        thanaReponse = await thanaReponse.json()
        setPresentthanaList(thanaReponse?.item)
      }
    }
    fetchMyAPI();
    fetchThana();

  }, []);

  async function handlePresentDistrict(evt) {
    setPresentDisId(evt.target.value)
    let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt.target.value}`)
    thanaReponse = await thanaReponse.json()
    setPresentthanaList(thanaReponse?.item)
  }

  async function handlePresentThana(evt) {
    setPresentThanaId(evt.target.value)
    console.log(evt.target.value)
  }

  async function permanenThandleThana(evt) {
    setPermanentThanaId(evt.target.value)
    // console.log(evt.target.value)
  }

  async function handlePermanentDistrict(evt) {
    setPermanentDISId(evt.target.value)
    let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt.target.value}`)
    thanaReponse = await thanaReponse.json()
    setPermanentThanaList(thanaReponse?.item)
  }
  async function handlePermanentDistrict2(evt, thana) {
    setPermanentDISId(evt)
    let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt}`)
    thanaReponse = await thanaReponse.json()
    setPermanentThanaList(thanaReponse?.item)
    if (thanaReponse?.item.length > 0) {
      setPermanentThanaId(thana);
    }

  }

  const [paymentStatus, setpaymentStatus] = useState(false)
  function paymentPage() {
    var win = window.open('https://shebashikkha.com/public/online/application-fee/pay?registrationId=' + payId);
    var timer = setInterval(function () {

      if (win.closed) {
        clearInterval(timer);
        fetch('https://shebashikkha.com/public/single/applicant/info?registrationId=' + payId + "&instituteId=" + parseInt(match.params.id))
          .then(response =>
            response.json()
          ).then(data => {
            if (data?.item?.paymentStatus === "Paid") {
              setpaymentStatus(true)
            }
          })
          .catch(error => {
            console.log(error)
          });
      }
    }, 3000);
  }

  function downloadPdf() {
    window.open('https://shebashikkha.com/public/confirmation/letter/download?registrationId=' + payId + "&inatituteId=" + parseInt(match.params.id));
  }

  function viewPayment() {
    history.push("/online-applicant-fee-payment/" + parseInt(match.params.id));
  }

  const [viewData, setviewData] = useState();
  const [submit, setsubmit] = useState({});

  // console.log('info', info);

  async function nextData(values) {

    let postData = {
      "academicYear": info?.academicYear,
      "bloodGroup": values.bloodGroup,
      "fatherName": values.fatherName,
      "fatherNameBangla": values.fatherNameBangla,
      "studentImageFileContent": imageFileContent === undefined ? "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" : imageFileContent,
      "studentImageFileSave": true,
      "localGuardianMobile": values.guardianMobile,
      "identificationId": info?.identificationId,
      "studentImageFileName": imageFileName === undefined ? "no_image.png" : imageFileName,
      "instituteId": info?.instituteId,
      "motherName": values.motherName,
      // "motherNamebangla": values.motherNameBangla,
      "dateOfBirth": values.dateOfBirth,
      "gender": values.gender,
      "applicantName": values.studentName,
      "applicantNameBangla": values.studentNameBangla,
      "religion": values.religion,
      "quota": values.quota,
      "birthCertificatNo": values.birthCertificatNo,
      "nationality": values.nationality,
      "autism": values.autism,
      "fatherMobile": values.fatherMobile,
      "fatherOccupation": values.fatherOccupation,
      "fatherIncome": values.fatherIncome,
      "motherNameBangla": values.motherNameBangla,
      "motherMobile": values.motherMobile,
      "motherNid": values.motherNid,
      "fatherNid": values.fatherNid,
      "motherOccupation": values.motherOccupation,
      "motherIncome": values.motherIncome,
      "localGuardianName": values.localGuardianName,
      /****Address objects***/
      "presentPostOffice": values.presentPostOffice,
      "presentThanaId": presentThanaId,
      "presentVillege": values.presentVillege,
      "permanentVillege": values.permanentVillege,
      "permanentPostOffice": values.permanentPostOffice,
      "permanentThanaId": permanentThanaId,
      // other
      "examName": values.examName,
      "passingYear": values.passingYear,
      "registrationNo": values.registrationNo,
      "examRoll": values.examRoll,
      "examGroup": values.examGroup,
      "grade": values.grade,
      "gpa": values.gpa,
      "gpaWithout4Th": values.gpaWithout4Th,
      "board": values.board,
    }
    //console.log(postData)
    setsubmit(postData);
    setStep(2);
  };

  const [message, setMessage] = useState('');

  const onSubmitFinal = (e) => {
    e.preventDefault();
    finalSubmit();
  };
  const [loading, setLoading] = React.useState(false);
  async function finalSubmit() {
    submit.compulsorySubjectIds = [...elective1, ...elective2, ...elective3, ...electiveOptionalMain];
    submit.optionalSubjectIds = electiveOptionalChild;
    setLoading(true);
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ROOT}/public/college/applicant-info/save`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submit)
      });
      if (rawResponse.status === 201 || rawResponse.status === 200) {
        const content = await rawResponse.json();
        // console.log(content);
        setLoading(false);
        if (content.messageType === 1) {
          setsuccessPay(true);
          setStep(3);
          setpayId(content.item)
          setviewData(submit)
          setsubmit(null);
        }
        if (content.messageType === 0) {
          ;
          setStep(4);
          setMessage(content.message)
        }
      } else {
        alert("Something went wrong");
        setLoading(false);
      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
    }

  }

  function generate(element) {
    console.log(element)
    return [0, 1, 2].map((value) =>
      React.cloneElement(element, {
        key: value,
      }),
    );
  };
  const [compulsorySubjectIds, setcompulsorySubjectIds] = useState([]);
  const [elective1, setelective1] = useState([]);
  const [elective2, setelective2] = useState([]);
  const [elective3, setelective3] = useState([]);
  const [optionalSubjectIds, setoptionalSubjectIds] = useState([]);
  const [electiveOptionalMain, setelectiveOptionalMain] = useState([]);
  const [electiveOptionalChild, setelectiveOptionalChild] = useState([]);



  const changeElective1 = (e, subjectId) => {
    setelective1([subjectId])
  };
  const changeElective2 = (e, subjectId) => {
    setelective2([subjectId])
  };
  const changeElective3 = (e, subjectId) => {
    setelective3([subjectId])
  };

  const changeElectiveOptionalMain = (e, subjectId) => {
    var requiredCheckboxes = $('.myoption :checkbox[required]');
    requiredCheckboxes.change(function () {
      if (requiredCheckboxes.is(':checked')) {
        requiredCheckboxes.removeAttr('required');
      } else {
        requiredCheckboxes.attr('required', 'required');
      }
    });
    let val = [...electiveOptionalMain, subjectId]
    setelectiveOptionalMain(val)
  };

  function clearElectiveOptionalMain() {
    setelectiveOptionalMain([])
    var node_list = document.getElementsByClassName('elective-main');
    for (var i = 0; i < node_list.length; i++) {
      node_list[i].checked = false;
    }
  }

  const changeElectiveOptionalChild = (e, subjectId) => {
    setelectiveOptionalChild([subjectId])
  };
  function clearElectiveOptionalChild() {
    setelectiveOptionalChild([])
    var node_list = document.getElementsByClassName('elective-child');
    for (var i = 0; i < node_list.length; i++) {
      node_list[i].checked = false;
    }

  }

  const optx = [1, 7];

  const [sameAs, setSameAs] = useState(false);


  return (
    <Grid container>
      <Grid item xs={12}>
        <Header details={{ instituteName: info?.instituteName, address: info?.instituteAddress, image: info?.instituteImage }} />
      </Grid>

      <Grid item xs={12} style={{ background: "#fff", boxShadow: "rgb(216 216 216) 0px 0px 15px 0px", padding: "15px" }}>
        <AppMenu details={{ identificationId: info?.identificationId, instituteId: info?.instituteId, downloadFlag: info?.downloadFlag }} />
        <Divider />
        <Stepper linear={true} activeStep={step - 1}>
          <Step>
            <StepLabel >Student Basic Information</StepLabel>
          </Step>
          {/* <Step>
            <StepLabel>Subject Information</StepLabel>
          </Step> */}
          <Step>
            <StepLabel>Final Result</StepLabel>
          </Step>
        </Stepper>
        <Divider />
        {!successPay && step === 1 &&
          <div className={classes.formWrapper}>

            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={values => {
                nextData(values);
              }}
            >

              {(formikProps) => {
                const { values, handleChange } = formikProps;
                return (
                  <Form>

                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Typography style={{ fontSize: "24px", textAlign: "center", marginBottom: "10px", color: "#009688", fontWeight: "500" }}>
                          Student Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 0 }}>
                        <Typography>
                          Basic Information
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Textfield
                          name="studentName"
                          label="Applicant Name"

                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Textfield
                          name="studentNameBangla"
                          label="ছাত্র ছাত্রীর নাম(বাংলা)"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="gender"
                          label="Gender"
                          options={genederData}

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield inputProps={{ maxLength: 11 }}
                          name="stuMobile"
                          label="Student Mobile"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Select
                          name="religion"
                          label="Religion"
                          options={religionData}

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <DateTimePicker
                          name="dateOfBirth"
                          label="Date Of Birth"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="bloodGroup"
                          label="Blood Group"
                          options={bloodData}

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="quota"
                          label="Quota"
                          options={quotaData}

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="birthCertificatNo"
                          label="Birth Certificate No"
                         
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="nationality"
                          label="Nationality"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="autism"
                          label="Autism"
                          options={autismData}

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Upload required id="imgfile" style={{ width: "100%" }} classes={{ root: classes.root }} variant="outlined" label="Student Photo" focused={imageFileSave == '' && true} name="file" type="file" accept="image/*" onChange={(e) => {
                          imageUpload(e)

                        }} />
                        {/* <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" style={{ display: imageFileSave === '' ? 'none' : imageFileSave === true ? 'none' : 'inherit' }}>Required</p> */}
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Father's Information
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Textfield
                          name="fatherName"
                          label="Father's Name( English )"

                        />
                      </Grid>


                      <Grid item xs={6}>
                        <Textfield
                          name="fatherNameBangla"
                          label="পিতার নাম (বাংলায়)"

                        />
                      </Grid>


                      <Grid item xs={12} sm={6}>
                        <Textfield inputProps={{ maxLength: 11 }}
                          name="fatherMobile"
                          label="Father's Mobile"

                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="fatherNid"
                          label="Father's NID"

                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Select
                          name="fatherOccupation"
                          label="Father's Occupation"
                          options={fatheroccupationdata}

                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="fatherIncome"
                          label="Father's Monthly Income"
                          type="number"

                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Mother's Information
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Textfield
                          name="motherName"
                          label="Mother's Name( English )"

                        />
                      </Grid>


                      <Grid item xs={6}>
                        <Textfield
                          name="motherNameBangla"
                          label="মাতার নাম (বাংলায়)"

                        />
                      </Grid>


                      <Grid item xs={12} sm={6}>
                        <Textfield inputProps={{ maxLength: 11 }}
                          name="motherMobile"
                          label="Mother's Mobile"

                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="motherNid"
                          label="Mother's NID"

                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Select
                          name="motherOccupation"
                          label="Mother's Occupation"
                          options={motheroccupationdata}

                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="motherIncome"
                          label="Mother's Monthly Income"
                          type="number"

                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Present Address Information
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="presentVillege"
                          label="Village"
                        //
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="presentPostOffice"
                          label="Post Office"

                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        {/* <SelectDistrict
                      name="presentDistrict"
                      label="District"
                      value={districtList}
                      onChange={handlePresentDistrict}
                    /> */}
                        <TextF value={presentDisId} required select={true} variant="outlined" fullWidth label="District" onChange={(e) => {
                          handlePresentDistrict(e);
                        }} >
                          {districtList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.districtId}>
                                {item.districtName}
                              </MenuItem>
                            )
                          })}
                        </TextF>

                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextF value={presentThanaId} required select={true} variant="outlined" fullWidth label="Thana" onChange={(e) => {
                          handlePresentThana(e);
                        }} >
                          {thanaList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.thanaId}>
                                {item.thanaName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 15, display: "flex", alignContent: "center", alignItems: "center" }}>
                        <div>
                          <Typography>
                            Permanent Address Information
                          </Typography>
                        </div>
                        <div style={{ display: "flex", alignContent: "center", alignItems: "center", marginLeft: 25 }}>
                          <Checkbox
                            checked={sameAs}
                            disabled={presentThanaId == '' ? true : false}
                            onChange={() => {
                              setSameAs(!sameAs);
                              if (!sameAs === true) {
                                formikProps.setFieldValue("permanentVillege", values.presentVillege);
                                formikProps.setFieldValue("permanentPostOffice", values.presentPostOffice);
                                handlePermanentDistrict2(presentDisId, presentThanaId);

                              } else if (!sameAs === false) {
                                formikProps.setFieldValue("permanentVillege", '');
                                formikProps.setFieldValue("permanentPostOffice", '');
                                setPermanentDISId('');
                                setPermanentThanaId('');
                              }
                            }}
                          />
                          <Typography>
                            Same as Present Address
                          </Typography>

                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="permanentVillege"
                          label="Village"
                        //
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="permanentPostOffice"
                          label="Post Office"

                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextF value={permanentDisId} variant="outlined" select={true} fullWidth label="District" onChange={handlePermanentDistrict} >
                          {districtList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.districtId}>
                                {item.districtName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextF value={permanentThanaId}  variant="outlined" select={true} fullWidth label="Thana" onChange={(e) => {
                          permanenThandleThana(e);
                        }} >
                          {permanentThanaList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.thanaId}>
                                {item.thanaName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>
                      {/***********************************/}

                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Local Guardian Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="localGuardianName"
                          label="Guardian Name"

                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield inputProps={{ maxLength: 11 }}
                          name="guardianMobile"
                          label="Mobile Number"

                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Exam Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Select
                          name="examName"
                          label="Exam Name"
                          options={examNameData}

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="passingYear"
                          label="Passing Year"

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="registrationNo"
                          label="Registration No"

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="examRoll"
                          label="Exam Roll"

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Select
                          name="examGroup"
                          label="Exam Group"
                          options={examGroupData}

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Select
                          name="grade"
                          label="Grade"
                          options={gradeData}

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="gpa"
                          label="GPA"
                          type="number"

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="gpaWithout4Th"
                          label="GPA (Withouth 4th Subject)"
                          type="number"

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Select
                          name="board"
                          label="Board"
                          options={boardData}

                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <Submit type="submit" style={{ float: "right" }} color="primary" variant="contained" onClick={() => validateOthers()} >
                          Next
                        </Submit>
                        {/* <Submit type="reset" color="secondary" variant="contained" style={{ marginLeft: 5 }} onClick={() => clearothers()}>
                          Reset
                        </Submit> */}
                      </Grid>


                    </Grid>

                  </Form>
                );
              }}
            </Formik>

          </div>
        }
        {!successPay && step === 2 &&

          <div className={classes.formWrapper}>
            <form onSubmit={onSubmitFinal}>
              <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                  <Typography style={{ fontSize: "24px", color: "#fff", fontWeight: "500", cursor: "pointer" }} onClick={() => null}>
                    ← Back
                  </Typography>
                  {/* <Typography style={{ fontSize: "24px", color: "#009688", fontWeight: "500" }}>
                    Subject Information
                  </Typography> */}
                  <Typography style={{ fontSize: "24px", color: "#fff", fontWeight: "500" }}>
                    Data
                  </Typography>
                </div>
                <Divider style={{ marginBottom: 30 }} />
              </Grid>
              <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }} >
                  <Grid item xs={12} >
                    <Typography style={{ fontSize: "18px", marginTop: "15px", marginBottom: "0px", color: "#009688", fontWeight: "500", textDecoration: "underline" }}>
                      Compulsory Subject(s):
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex", flexWrap: "wrap" }}>
                    {subinfo.filter(item => item?.subjectStatus === 0)?.map((final, key) => {
                      return (
                        <span key={key} style={{ fontSize: 15 }} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 sublistx">{final?.subjectName}</span>
                      )
                    })}
                  </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 15 }} >
                  <Grid item xs={12} style={{ marginBottom: -10 }}>
                    <Typography style={{ fontSize: "16px", color: "#009688", textDecoration: "underline" }}>
                      Group Subject(s):
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ul style={{ paddingLeft: "0" }}>
                      {subinfo.filter(item => item?.subjectStatus === 2).map((final, key) => {
                        return (
                          <span key={key} style={{ fontSize: 15 }} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 sublistx">{final?.subjectName}</span>
                        )
                      })}
                    </ul>
                  </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginTop: 15 }}>
                  {subinfo.filter(item => item?.subjectStatus === 3)?.length > 0 &&
                    <Grid item xs={12} sm={4}>
                      <Grid item xs={12} style={{ marginBottom: -10 }}>
                        <Typography style={{ fontSize: "16px", color: "#009688", textDecoration: "underline" }}>
                          Elective (Group-A):
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <ul style={{ paddingLeft: "0" }}>
                          {subinfo.filter(item => item?.subjectStatus === 3).map((final, key) => {
                            return (
                              <li key={key} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock ">

                                <input
                                  type="radio"
                                  name='elective-1'
                                  required
                                  // disabled={optionalSubjectIds.includes(final?.subjectId)}
                                  onChange={(e) => changeElective1(e, final?.subjectId)}
                                />
                                {final?.subjectName}
                              </li>
                            )
                          })}
                        </ul>
                      </Grid>
                    </Grid>
                  }

                  {subinfo.filter(item => item?.subjectStatus === 5)?.length > 0 &&
                    <Grid item xs={12} sm={4}>
                      <Grid item xs={12} style={{ marginBottom: -10 }}>
                        <Typography style={{ fontSize: "16px", color: "#009688", textDecoration: "underline" }}>
                          Elective (Group-B):
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <ul style={{ paddingLeft: "0" }}>
                          {subinfo.filter(item => item?.subjectStatus === 5).map((final, key) => {
                            return (
                              <li key={key} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock ">

                                <input
                                  type="radio"
                                  name='elective-2'
                                  required
                                  // disabled={optionalSubjectIds.includes(final?.subjectId)}
                                  onChange={(e) => changeElective2(e, final?.subjectId)}
                                />
                                {final?.subjectName}
                              </li>
                            )
                          })}
                        </ul>
                      </Grid>
                    </Grid>
                  }

                  {subinfo.filter(item => item?.subjectStatus === 6)?.length > 0 &&
                    <Grid item xs={12} sm={4} >
                      <Grid item xs={12} style={{ marginBottom: -10 }}>
                        <Typography style={{ fontSize: "16px", color: "#009688", textDecoration: "underline" }}>
                          Elective (Group-C):
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <ul style={{ paddingLeft: "0" }}>
                          {subinfo.filter(item => item?.subjectStatus === 6).map((final, key) => {
                            return (
                              <li key={key} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock ">

                                <input
                                  type="radio"
                                  name='elective-3'
                                  required
                                  // disabled={optionalSubjectIds.includes(final?.subjectId)}
                                  onChange={(e) => changeElective3(e, final?.subjectId)}
                                />
                                {final?.subjectName}
                              </li>
                            )
                          })}
                        </ul>
                      </Grid>
                    </Grid>
                  }
                </Grid>
                {subinfo.filter(item => item?.subjectStatus === 7)?.length > 0 &&
                  <Grid item xs={12} >
                    <Grid item xs={12} style={{ marginBottom: -10 }}>
                      <Typography style={{ fontSize: "16px", color: "#009688", textDecoration: "underline" }}>
                        Elective:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ul style={{ paddingLeft: "0" }}>
                        <div className='myoption'>
                          {subinfo.filter(item => item?.subjectStatus === 7).map((final, key) => {
                            return (
                              <li key={key} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock ">

                                <input
                                  type="checkbox"
                                  name='elective-main'
                                  required
                                  class='elective-main'
                                  disabled={electiveOptionalChild.includes(final?.subjectId)}
                                  onChange={(e) => changeElectiveOptionalMain(e, final?.subjectId)}
                                />
                                {final?.subjectName}
                              </li>
                            )
                          })}
                        </div>
                        <span style={{ color: 'blue', marginLeft: 5, cursor: "pointer" }} onClick={clearElectiveOptionalMain}>Clear Selection</span>
                      </ul>
                    </Grid>
                  </Grid>
                }

                {subinfo.filter((item) => { return optx.includes(item.subjectStatus) })?.length > 0 &&
                  <Grid item xs={12} >
                    <Grid item xs={12} style={{ marginBottom: -10 }}>
                      <Typography style={{ fontSize: "16px", color: "#009688", textDecoration: "underline" }}>
                        4th Subject (Choose from this list):
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ul style={{ paddingLeft: "0" }}>
                        {subinfo.filter((item) => { return optx.includes(item.subjectStatus) }).map((final, key) => {
                          return (
                            <li key={key} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock ">
                              <input
                                type="radio"
                                name='elective-child'
                                class='elective-child'
                                required
                                key={key}
                                disabled={electiveOptionalMain.includes(final?.subjectId)}
                                onChange={(e) => changeElectiveOptionalChild(e, final?.subjectId)}
                              />
                              {final?.subjectName}
                            </li>
                          )
                        })}
                        <span style={{ color: 'blue', marginLeft: 5, cursor: "pointer" }} onClick={clearElectiveOptionalChild}>Clear Selection</span>
                      </ul>
                    </Grid>
                  </Grid>
                }

              </div>





              <p className='MuiTypography-root MuiTypography-body1'><input type="checkbox" className="jss66" required name="terms" /> I promise that the above information is absolutely true. I will abide by all the rules and regulations of <strong><u>{info?.instituteName}</u></strong> I will be obliged to accept any action taken by the college authorities if any of my conduct violates the law and order of the college or tarnishes the image of the institution.</p>
              <div style={{ display: "flex", justifyContent: "flex-end", alignContent: "center", alignItems: "center" }}>
                {loading && (
                  <CircularProgress
                    size={24}
                    style={{ marginRight: 10 }}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
                <Buttonh variant="contained" color="primary" style={{}} type="submit" >Submit</Buttonh>
              </div>

            </form>

          </div>
        }
        {successPay && step === 3 &&
          <>

            <div className="h-100 w-100 p-5 text-center" style={{ marginTop: "2%" }}>
              <h2 className='MuiTypography-root MuiTypography-h5' style={{ color: '#009688' }}>Congratulation! {viewData?.studentName} </h2>
              <p className='MuiTypography-root MuiTypography-h6'>Your registration is completed at {info?.instituteName}.</p>
              <span className='MuiTypography-root MuiTypography-h6'>Student ID : {info?.customStudentId}</span>
              {/* <p className='MuiTypography-root MuiTypography-h6'>College Code/EIIN : {info?.eiinNo}</p> */}
              <p className='MuiTypography-root MuiTypography-h6'>Institute ID : {info?.instituteId}</p>
              {/* <p className='MuiTypography-root MuiTypography-h6'>Applicant ID : {payId}</p> */}
              <p className='MuiTypography-root MuiTypography-h6' style={{ marginTop: 0, textAlign: "center" }}><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => window.open(
                `${process.env.REACT_APP_API_ROOT}/public/confirmation/letter/download/for/college?identificationId=${info?.identificationId}&instituteId=${info?.instituteId}`,
                '_blank' // <- This is what makes it open in a new window.
              )}><u>Click Here</u></span> to download your form.</p>
            </div>
          </>
        }

        {!successPay && step === 4 &&
          <>
            <p className='MuiTypography-root MuiTypography-h5' style={{ color: "red", marginTop: 40, textAlign: "center" }}>{message}</p>
            <p className='MuiTypography-root MuiTypography-h6' style={{ marginTop: 0, textAlign: "center" }}><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => window.open(
              `${process.env.REACT_APP_API_ROOT}/public/confirmation/letter/download/for/college?identificationId=${info?.identificationId}&instituteId=${info?.instituteId}`,
              '_blank' // <- This is what makes it open in a new window.
            )}><u>Click Here</u></span> sfasfasfsa to download your form.</p>
          </>
        }

      </Grid>
    </Grid>
  );
};

export default ApplicationForm;
