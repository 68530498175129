import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header';
import { Formik, Form, yupToFormErrors } from 'formik';
import { Checkbox, CircularProgress, Divider, List, ListItem, ListItemIcon, ListItemText, Snackbar, Step, StepButton, StepIcon, StepLabel, Stepper, TextField as Upload } from '@material-ui/core';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import Textfield from '../../Components/FormsUI/Textfield';
import Select from '../../Components/FormsUI/Select';
import DateTimePicker from '../../Components/FormsUI/DataTimePicker';
import { fatheroccupationdata, motheroccupationdata, autismData, bloodData, genederData, quotaData, religionData, examNameData, gradeData, boardData, examGroupData } from '../../data/data';
import { Link, useHistory } from "react-router-dom";
import useReactRouter from 'use-react-router';
import { Button as Submit } from '@material-ui/core';
import { Button as Buttonh } from '@material-ui/core';
import { green, purple } from "@material-ui/core/colors";
import { TextField as TextF, MenuItem } from '@material-ui/core';
import $ from 'jquery';
import AppMenu from '../../Components/Header/appmenuschool';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(8),
  },
  root: {
    color: green[900],
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)" // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C4C4C4" // customized
      }
    }
  }
}));






const SchoolApplicationForm = () => {

  const info = JSON.parse(sessionStorage.getItem('info'));
  const subinfo = JSON.parse(sessionStorage.getItem('subinfo'));

  const history = useHistory();

  if (info === null) {
    history.push("/");
  }

  const INITIAL_FORM_STATE = {
    studentName: info?.studentName,
    fatherName: info?.fatherName,
    motherName: info?.motherName,
    studentNameBangla: info?.studentNameBangla,
    fatherNameBangla: info?.fatherNameBangla,
    motherNameBangla: info?.motherNameBangla,
    guardianMobile: info?.guardianMobile,
    dateOfBirth: info?.studentDOB,
    religion: info?.studentReligion,
    gender: info?.studentGender,
    bloodGroup: info?.bloodGroup,
    quota: '',
    birthCertificatNo: '',
    nationality: '',
    autism: '',
    fatherMobile: '',
    fatherOccupation: '',
    fatherIncome: '',
    fatherNid: '',
    motherMobile: '',
    motherNid: '',
    motherOccupation: '',
    motherIncome: '',
    presentVillage: '',
    presentPostOffice: '',
    permanentVillege: '',
    permanentPostOffice: '',
    permanentThanaId: '',
    localGuardianName: '',
    presentDistrict: '',
    presentThanaId: '',
    permanentDistrict: '',
  };

  const FORM_VALIDATION = Yup.object().shape({
    studentName: Yup.string()
      .required('Required'),
    fatherName: Yup.string()
      .required('Required'),
    motherName: Yup.string()
      .required('Required'),
    guardianMobile: Yup.string()
      .required('Required'),
    religion: Yup.string()
      .required('Required'),
    gender: Yup.string()
      .required('Required'),
    dateOfBirth: Yup.string()
      .required('Required').nullable(),
    /**********************/
    presentVillege: Yup.string(),
    presentPostOffice: Yup.string()
      .required('Required'),
    permanentPostOffice: Yup.string()
      .required('Required'),
    permanentVillege: Yup.string(),
  });



  const classes = useStyles();
  const { match } = useReactRouter();


  const [payId, setpayId] = useState();
  const [successPay, setsuccessPay] = useState(false);
  const [step, setStep] = useState(1);



  const [imageFileContent, setimageFileContent] = useState();
  const [imageFileSave, setimageFileSave] = useState('');
  const [imageFileName, setimageFileName] = useState(undefined);

  function clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch (ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }
  const imageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file?.size > 600000) {
      // file.target.value = null;
      clearFileInput(document.getElementById("imgfile"));
      setimageFileSave(false);
      setimageFileContent('');
      setimageFileName(undefined);
      alert("Image size should be less than 600KB");
      return;
    };
    if (reader !== undefined && file !== undefined) {
      setimageFileSave(true);

      reader.onloadend = () => {
        // setFile(file)
        // setSize(file.size);
        // setName(file.name)
        // setImagePreview(reader.result)
        setimageFileName(file.name)
        setimageFileContent(reader.result.split(',')[1])
      }
      reader.readAsDataURL(file);
    }
  };

  const validateOthers = () => {
    // console.log('sss')
  }
  const [districtList, setdistrictList] = useState([]);
  const [thanaList, setPresentthanaList] = useState([]);
  const [permanentThanaList, setPermanentThanaList] = useState([]);
  const [presentThanaId, setPresentThanaId] = useState('');
  const [permanentThanaId, setPermanentThanaId] = useState('');
  const [presentDisId, setPresentDisId] = useState('');
  const [permanentDisId, setPermanentDISId] = useState('');
  useEffect(() => {

    async function fetchMyAPI() {
      let disReponse = await fetch('https://api.shebashikkha.com/location/info/district-list')
      disReponse = await disReponse.json()
      //  console.log(disReponse)
      setdistrictList(disReponse?.item)
    }

    async function fetchThana() {
      if (info.districtId != null) {
        let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${info.districtId}`)
        thanaReponse = await thanaReponse.json()
        setPresentthanaList(thanaReponse?.item)
      }
    }
    fetchMyAPI();
    fetchThana();

  }, []);

  async function handlePresentDistrict(evt) {
    setPresentDisId(evt.target.value)
    let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt.target.value}`)
    thanaReponse = await thanaReponse.json()
    setPresentthanaList(thanaReponse?.item)
  }

  async function handlePresentThana(evt) {
    setPresentThanaId(evt.target.value)
    console.log(evt.target.value)
  }

  async function permanenThandleThana(evt) {
    setPermanentThanaId(evt.target.value)
    // console.log(evt.target.value)
  }

  async function handlePermanentDistrict(evt) {
    setPermanentDISId(evt.target.value)
    let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt.target.value}`)
    thanaReponse = await thanaReponse.json()
    setPermanentThanaList(thanaReponse?.item)
  }
  async function handlePermanentDistrict2(evt, thana) {
    setPermanentDISId(evt)
    let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt}`)
    thanaReponse = await thanaReponse.json()
    setPermanentThanaList(thanaReponse?.item)
    if (thanaReponse?.item.length > 0) {
      setPermanentThanaId(thana);
    }

  }

  const [paymentStatus, setpaymentStatus] = useState(false)
  function paymentPage() {
    var win = window.open('https://shebashikkha.com/public/online/application-fee/pay?registrationId=' + payId);
    var timer = setInterval(function () {

      if (win.closed) {
        clearInterval(timer);
        fetch('https://shebashikkha.com/public/single/applicant/info?registrationId=' + payId + "&instituteId=" + parseInt(match.params.id))
          .then(response =>
            response.json()
          ).then(data => {
            if (data?.item?.paymentStatus === "Paid") {
              setpaymentStatus(true)
            }
          })
          .catch(error => {
            console.log(error)
          });
      }
    }, 3000);
  }

  function downloadPdf() {
    window.open('https://shebashikkha.com/public/confirmation/letter/download?registrationId=' + payId + "&inatituteId=" + parseInt(match.params.id));
  }

  function viewPayment() {
    history.push("/online-applicant-fee-payment/" + parseInt(match.params.id));
  }

  const [viewData, setviewData] = useState();
  const [submit, setsubmit] = useState({});

  const [message, setMessage] = useState('');
  const [loading, setLoading] = React.useState(false);

  async function nextData(values) {
    let postData = {
      "academicYear": info?.academicYear,
      "bloodGroup": values.bloodGroup,
      "fatherName": values.fatherName,
      "fatherNameBangla": values.fatherNameBangla,
      "studentImageFileContent": imageFileContent === undefined ? "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" : imageFileContent,
      "studentImageFileSave": true,
      "localGuardianMobile": values.guardianMobile,
      "identificationId": info?.identificationId,
      "studentImageFileName": imageFileName === undefined ? "no_image.png" : imageFileName,
      "instituteId": info?.instituteId,
      "motherName": values.motherName,
      // "motherNamebangla": values.motherNameBangla,
      "dateOfBirth": values.dateOfBirth,
      "gender": values.gender,
      "applicantName": values.studentName,
      "applicantNameBangla": values.studentNameBangla,
      "religion": values.religion,
      "quota": values.quota,
      "birthCertificatNo": values.birthCertificatNo,
      "nationality": values.nationality,
      "autism": values.autism,
      "fatherMobile": values.fatherMobile,
      "fatherOccupation": values.fatherOccupation,
      "fatherIncome": values.fatherIncome,
      "motherNameBangla": values.motherNameBangla,
      "motherMobile": values.motherMobile,
      "motherNid": values.motherNid,
      "fatherNid": values.fatherNid,
      "motherOccupation": values.motherOccupation,
      "motherIncome": values.motherIncome,
      "localGuardianName": values.localGuardianName,
      /****Address objects***/
      "presentPostOffice": values.presentPostOffice,
      "presentThanaId": presentThanaId,
      "presentVillege": values.presentVillege,
      "permanentVillege": values.permanentVillege,
      "permanentPostOffice": values.permanentPostOffice,
      "permanentThanaId": permanentThanaId,
    }
    setLoading(true);
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ROOT}/public/college/applicant-info/save`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });
      if (rawResponse.status === 201 || rawResponse.status === 200) {
        const content = await rawResponse.json();
        // console.log(content);
        setLoading(false);
        if (content.messageType === 1) {
          setsuccessPay(true);
          setStep(3);
          setpayId(content.item)
          setviewData(postData)
          setsubmit(null);
        }
        if (content.messageType === 0) {
          setStep(4);
          setMessage(content.message)
        }
      } else {
        alert("Something went wrong");
        setLoading(false);
      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
    }
  };




  const [sameAs, setSameAs] = useState(false);


  return (
    <Grid container>
      <Grid item xs={12}>
        <Header details={{ instituteName: info?.instituteName, address: info?.instituteAddress, image: info?.instituteImage }} />
      </Grid>

      <Grid item xs={12} style={{ background: "#fff", boxShadow: "rgb(216 216 216) 0px 0px 15px 0px", padding: "15px" }}>
        <AppMenu details={{ identificationId: info?.identificationId, instituteId: info?.instituteId, downloadFlag: info?.downloadFlag }} />
        <Divider />
        <Stepper linear={true} activeStep={step - 1}>
          <Step>
            <StepLabel >Student Basic Information</StepLabel>
          </Step>
          {/* <Step>
            <StepLabel>Subject Information</StepLabel>
          </Step> */}
          <Step>
            <StepLabel>Final Result</StepLabel>
          </Step>
        </Stepper>
        <Divider />
        {!successPay && step === 1 &&
          <div className={classes.formWrapper}>

            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={values => {
                nextData(values);
              }}
            >

              {(formikProps) => {
                const { values, handleChange } = formikProps;
                return (
                  <Form>

                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Typography style={{ fontSize: "24px", textAlign: "center", marginBottom: "10px", color: "#009688", fontWeight: "500" }}>
                          Student Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 0 }}>
                        <Typography>
                          Basic Information
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Textfield
                          name="studentName"
                          label="Applicant Name"

                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Textfield
                          name="studentNameBangla"
                          label="ছাত্র ছাত্রীর নাম(বাংলা)"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="gender"
                          label="Gender"
                          options={genederData}

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield inputProps={{ maxLength: 11 }}
                          name="stuMobile"
                          label="Student Mobile"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Select
                          name="religion"
                          label="Religion"
                          options={religionData}

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <DateTimePicker
                          name="dateOfBirth"
                          label="Date Of Birth"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="bloodGroup"
                          label="Blood Group"
                          options={bloodData}

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="quota"
                          label="Quota"
                          options={quotaData}

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="birthCertificatNo"
                          label="Birth Certificate No"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="nationality"
                          label="Nationality"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="autism"
                          label="Autism"
                          options={autismData}

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Upload required id="imgfile" style={{ width: "100%" }} classes={{ root: classes.root }} variant="outlined" label="Student Photo" focused={imageFileSave == '' && true} name="file" type="file" accept="image/*" onChange={(e) => {
                          imageUpload(e)

                        }} />
                        {/* <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" style={{ display: imageFileSave === '' ? 'none' : imageFileSave === true ? 'none' : 'inherit' }}>Required</p> */}
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Father's Information
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Textfield
                          name="fatherName"
                          label="Father's Name( English )"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Textfield
                          name="fatherNameBangla"
                          label="পিতার নাম (বাংলায়)"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield inputProps={{ maxLength: 11 }}
                          name="fatherMobile"
                          label="Father's Mobile"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="fatherNid"
                          label="Father's NID"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Select
                          name="fatherOccupation"
                          label="Father's Occupation"
                          options={fatheroccupationdata}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="fatherIncome"
                          label="Father's Monthly Income"
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Mother's Information
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Textfield
                          name="motherName"
                          label="Mother's Name( English )"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Textfield
                          name="motherNameBangla"
                          label="মাতার নাম (বাংলায়)"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield inputProps={{ maxLength: 11 }}
                          name="motherMobile"
                          label="Mother's Mobile"

                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="motherNid"
                          label="Mother's NID"

                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Select
                          name="motherOccupation"
                          label="Mother's Occupation"
                          options={motheroccupationdata}

                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="motherIncome"
                          label="Mother's Monthly Income"
                          type="number"

                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Present Address Information
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="presentVillege"
                          label="Village"
                        //
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="presentPostOffice"
                          label="Post Office"

                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        {/* <SelectDistrict
                      name="presentDistrict"
                      label="District"
                      value={districtList}
                      onChange={handlePresentDistrict}
                    /> */}
                        <TextF value={presentDisId} required select={true} variant="outlined" fullWidth label="District" onChange={(e) => {
                          handlePresentDistrict(e);
                        }} >
                          {districtList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.districtId}>
                                {item.districtName}
                              </MenuItem>
                            )
                          })}
                        </TextF>

                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextF value={presentThanaId} required select={true} variant="outlined" fullWidth label="Thana" onChange={(e) => {
                          handlePresentThana(e);
                        }} >
                          {thanaList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.thanaId}>
                                {item.thanaName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 15, display: "flex", alignContent: "center", alignItems: "center" }}>
                        <div>
                          <Typography>
                            Permanent Address Information
                          </Typography>
                        </div>
                        <div style={{ display: "flex", alignContent: "center", alignItems: "center", marginLeft: 25 }}>
                          <Checkbox
                            checked={sameAs}
                            disabled={presentThanaId == '' ? true : false}
                            onChange={() => {
                              setSameAs(!sameAs);
                              if (!sameAs === true) {
                                formikProps.setFieldValue("permanentVillege", values.presentVillege);
                                formikProps.setFieldValue("permanentPostOffice", values.presentPostOffice);
                                handlePermanentDistrict2(presentDisId, presentThanaId);

                              } else if (!sameAs === false) {
                                formikProps.setFieldValue("permanentVillege", '');
                                formikProps.setFieldValue("permanentPostOffice", '');
                                setPermanentDISId('');
                                setPermanentThanaId('');
                              }
                            }}
                          />
                          <Typography>
                            Same as Present Address
                          </Typography>

                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="permanentVillege"
                          label="Village"
                        //
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="permanentPostOffice"
                          label="Post Office"

                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextF value={permanentDisId} variant="outlined" select={true} fullWidth label="District" onChange={handlePermanentDistrict} >
                          {districtList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.districtId}>
                                {item.districtName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextF value={permanentThanaId} variant="outlined" select={true} fullWidth label="Thana" onChange={(e) => {
                          permanenThandleThana(e);
                        }} >
                          {permanentThanaList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.thanaId}>
                                {item.thanaName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>
                      {/***********************************/}

                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Local Guardian Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield
                          name="localGuardianName"
                          label="Guardian Name"

                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Textfield inputProps={{ maxLength: 11 }}
                          name="guardianMobile"
                          label="Mobile Number"

                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <Submit type="submit" style={{ float: "right" }} color="primary" variant="contained" onClick={() => validateOthers()} >
                          Save
                        </Submit>
                        {loading && (
                          <CircularProgress
                            size={24}
                            style={{ marginRight: 20, float: "right", marginTop: 8 }}
                          />
                        )}
                      </Grid>


                    </Grid>

                  </Form>
                );
              }}
            </Formik>

          </div>
        }

        {successPay && step === 3 &&
          <>
            <div className="h-100 w-100 p-5 text-center" style={{ marginTop: "2%" }}>
              <h2 className='MuiTypography-root MuiTypography-h5' style={{ color: '#009688' }}>Congratulation! {viewData?.studentName} </h2>
              <p className='MuiTypography-root MuiTypography-h6'>Your registration is completed at {info?.instituteName}.</p>
              <span className='MuiTypography-root MuiTypography-h6'>Student ID : {info?.customStudentId}</span>
              {/* <p className='MuiTypography-root MuiTypography-h6'>College Code/EIIN : {info?.eiinNo}</p> */}
              <p className='MuiTypography-root MuiTypography-h6'>Institute ID : {info?.instituteId}</p>
              {/* <p className='MuiTypography-root MuiTypography-h6'>Applicant ID : {payId}</p> */}
              <p className='MuiTypography-root MuiTypography-h6' style={{ marginTop: 0, textAlign: "center" }}><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => window.open(
                `${process.env.REACT_APP_API_ROOT}/public/confirmation/letter/download/for/college?identificationId=${info?.identificationId}&instituteId=${info?.instituteId}`,
                '_blank' // <- This is what makes it open in a new window.
              )}><u>Click Here</u></span> to download your form.</p>
            </div>
          </>
        }

        {!successPay && step === 4 &&
          <>
            <p className='MuiTypography-root MuiTypography-h5' style={{ color: "red", marginTop: 40, textAlign: "center" }}>{message}</p>
            <p className='MuiTypography-root MuiTypography-h6' style={{ marginTop: 0, textAlign: "center" }}><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => window.open(
              `${process.env.REACT_APP_API_ROOT}/public/confirmation/letter/download/for/college?identificationId=${info?.identificationId}&instituteId=${info?.instituteId}`,
              '_blank' // <- This is what makes it open in a new window.
            )}></span>{message}</p>
          </>
        }

      </Grid>
    </Grid>
  );
};

export default SchoolApplicationForm;
