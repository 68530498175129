import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  TextField as TextF,
  MenuItem,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core'
import { useHistory } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Textfield from '../../Components/FormsUI/Textfield';
import Button from '../../Components/FormsUI/Button';
import background from '../new.png'
import logo from '../logo.svg'
import { green } from "@material-ui/core/colors";


const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "white !important",
    color: 'white !important'
  },
  root: {
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'red',
    },
  },
}));

const INITIAL_FORM_STATE = {
  studentRoll: '',
  collegeCode: '',
};

const FORM_VALIDATION = Yup.object().shape({
  studentRoll: Yup.string()
    .required('Student ID/SSC Roll Roll is Required'),
  collegeCode: Yup.string()
    .required('Institute ID/EIIN No is Required'),
});



const SchoolLogin = (props) => {
  const classes = useStyles();

  const [check, setCheck] = useState(false)

  const history = useHistory();

  async function handleClass(evt) {
    history.push("/online-applicant-info/" + parseInt(evt.target.value));
  }


  const [loading, setLoading] = React.useState(false);
  async function submitata(values) {


    if(values.collegeCode===1000037){
      values.collegeCode=10214;
    }

    setLoading(true);
    let response = await fetch(`${process.env.REACT_APP_API_ROOT}/public/student/list/by/custom-id?customStudentId=${values.studentRoll}&instituteId=${values.collegeCode}`)
    response = await response.json()
    //console.log(response.item)
    if (response?.item?.length === 1) {
      sessionStorage.setItem('info', JSON.stringify(response?.item?.[0]));
      history.push("/online-applicant-info-school/" + response?.item?.[0]?.instituteId);
      setCheck(false);
      setLoading(false);
    } else {
      setCheck(true);
      setLoading(false);
    }
  }


  return (

    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '93vh', backgroundImage: "url(" + background + ")", width: "100%", backgroundRepeat: "no-repeat", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
      >

        <br></br>
        <Box
          minHeight="250px"
          minWidth="400px"
          border={1}
          borderColor="white"
          borderRadius="12px"
        >
          <div style={{ textAlign: "center", padding: 20, background: "#fff", boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)", borderRadius:10 }}>
            <img src={logo} style={{ height: 100 }} />
            <Typography variant="h4" component="h4" style={{ color: "#009688", textAlign: "center" }}>
              Online Payment
            </Typography>

            <Typography variant="h6" component="h6" style={{ color: "#009688", textAlign: "center" }}>
              (Admission /Registration /Form fillup)
            </Typography>
            <br></br>

            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={values => {
                //console.log(values)
                 submitata(values);
              }}
            >
              {(formikProps) => {
                const { values, handleChange } = formikProps;
                return (
                  <Form
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        submitata(values);
                      }
                    }}
                  >
                    <Textfield
                      name="studentRoll"
                      label="Student ID/SSC Roll"
                    />
                    <br></br>
                    <br></br>
                    <Textfield
                      name="collegeCode"
                      label="Institute ID/EIIN No"
                      type="number"

                    />
                    <br></br>
                    <br></br>
                    {check &&
                      <>
                        <Typography variant="h6" component="h6" style={{ color: "red" }}>
                          Check your Student ID/SSC Roll or Institute ID/EIIN No.
                        </Typography>
                        <br></br>
                      </>}
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                    <Button >Submit</Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Box>

      </Grid>
    </>
  );
};

export default SchoolLogin;