import React, { useState, useEffect } from "react";
import Footer from '../Components/Footer';
import {
  Box,
  Button,
  TextField as TextF,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core'
import { useHistory } from "react-router-dom";
import logo from './02.png'
import background from './new.png'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // minHeight: 80,
    alignItems: 'center',
    content: 'center',
    display: "flex",
    alignContent: "center",
    justifyContent: 'center',
    fontWeight: "bold",
    cursor: "pointer",
    // marginTop: 20,
    // marginBottom: 10,
    fontSize:20
  },
}));



const Home = (props) => {

  const history = useHistory();
  const onSubmitSchool = (e) => {
    e.preventDefault();
    history.push("/school-login");
  };  
  const onSubmitCollecge = (e) => {
    e.preventDefault();
    history.push("/login");
  };
  const onSubmitUniversity = (e) => {
    e.preventDefault();
    history.push("/university/login");
  };  
  const onSubmitPublic = (e) => {
    e.preventDefault();
    history.push("/public/login");
  };
  const classes = useStyles();

  return (

    <>

      <Grid
        container
        direction="column"
        alignItems="center"

        style={{ minHeight: '93vh', backgroundImage: "url(" + background + ")", width: "100%", backgroundRepeat: "no-repeat", backgroundRepeat: "no-repeat", backgroundSize: "cover", margin: "auto" }}
      >

        <img src={logo} style={{ height: 100, width: "100%", marginBottom: 50 }} />

        <Grid container spacing={3} style={{ paddingLeft: 10, paddingRight:10 }} >
          {/* <Grid item xs={12} sm={4}>
            <Paper id="schoola" className={classes.paper}>School Admission</Paper>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <Paper id="schoola" onClick={(e) => onSubmitSchool(e)} className={classes.paper}>School Admission</Paper>
          </Grid>          
          <Grid item xs={12} sm={6}>
            <Paper id="collegea" onClick={(e) => onSubmitCollecge(e)} className={classes.paper}>College Admission</Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper id="varsitya" className={classes.paper} onClick={(e) => onSubmitUniversity(e)} >National University Admission</Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper id="publica" className={classes.paper} onClick={(e) => onSubmitPublic(e)}>Public University Admission</Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;