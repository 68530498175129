import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.css'
import { Toolbar, AppBar, Typography, Grid, Container, createTheme, ThemeProvider } from '@material-ui/core';
import Footer from './Components/Footer';
import { green, teal } from '@material-ui/core/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: teal[500],
    },
    secondary: {
      main: teal[500],
    },
    typography: {
      allVariants: {
        color: teal[500]
      }
  },
}
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <div style={{maxWidth:980, margin:"0px auto",}}>
    <App />
      <Footer />
    </div>
     </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
