export const genederData = {
  "Male": "Male",
  "Female": "Female",
  "Other": "Other",
}
export const medium = {
  "Bangla": "Bangla",
  "English": "English",
}

export const religionData = {
  "Islam": "Islam",
  "Hinduism": "Hinduism",
  "Buddist": "Buddist",
  "Christian": "Christian",
  "Other": "Other",
}

export const quotaData = {
  "Not Applicable": "Not Applicable",
  "Teacher of Udayan": "Teacher of Udayan",
  "Freedom Fighter": "Freedom Fighter",
  "Catchment Area": "Catchment Area",
  "Ministry Of Education": "Ministry Of Education",
  "Teacher/Officer of Dhaka University": "Teacher/Officer of Dhaka University",

  
}

export const quotaData2 = {
  "General": "General",
  "Freedom Fighter": "Freedom Fighter",
  "Disable": "Disable",
  "Stipend": "Stipend",
  "Residential": "Residential",
  "Non-Residential":"Non-Residential",
  "Tribal Quota":"Tribal Quota",
  "Teacher of this Institute":"Teacher of this Institute",
  "Others":"Others",
}

export const maritalData = {
  "Unmarried": "Unmarried",
  "Married": "Married",
}

export const bloodData = {
  "A+": "A+",
  "A-": "A-",
  "AB+": "AB+",
  "AB-": "AB-",
  "B+": "B+",
  "B-": "B-",
  "O+": "O+",
  "O-": "O-",
}

export const autismData = {
  0: "No",
  1: "Yes",
}

export const fatheroccupationdata = {
  "Business": "Business",
  "Service": "Service",
  "Private Service": "Private Service",
  "Govt. Service": "Govt. Service",
  "Teacher": "Teacher",
  "Banker": "Banker",
  "Doctor": "Doctor",
  "Engineer": "Engineer",
  "Lawyer": "Lawyer",
  "Journalist": "Journalist",
  "Farmer": "Farmer",
  "Driver": "Driver",
  "Physician": "Physician",
  "Army": "Army",
  "Police Officer": "Police Officer",
  "Navy": "Navy",
  "Airforce": "Airforce",
  "Retired Person": "Retired Person",
  "NRB": "NRB",
  "BGB": "BGB",
  "N/A": "N/A",
  "NSI": "NSI",
}
export const motheroccupationdata = {
  "House Wife": "House Wife",
  "Business": "Business",
  "Service": "Service",
  "Private Service": "Private Service",
  "Govt. Service": "Govt. Service",
  "Teacher": "Teacher",
  "Banker": "Banker",
  "Doctor": "Doctor",
  "Engineer": "Engineer",
  "Lawyer": "Lawyer",
  "Journalist": "Journalist",
  "Farmer": "Farmer",
  "Driver": "Driver",
  "Physician": "Physician",
  "Army": "Army",
  "Police Officer": "Police Officer",
  "Navy": "Navy",
  "Airforce": "Airforce",
  "Retired Person": "Retired Person",
  "NRB": "NRB",
  "BGB": "BGB",
  "N/A": "N/A",
  "NSI": "NSI",
}

export const examNameData = {
  "SSC": "SSC",
  "Dakhil": "Dakhil",
  "Vocational ": "Vocational",
  "HSC ": "HSC",
  "Alim ": "Alim",
  "Others": "Others",
}

export const examGroupData = {
  "Science": "Science",
  "Humanities": "Humanities",
  "Business Studies": "Business Studies",
  "General": "General",
  "Other": "Other",
}

export const gradeData = {
  "A+": "A+",
  "A": "A",
  "A-": "A-",
  "B": "B",
  "C": "C",
  "D": "D",
}

export const boardData = {
  "Barisal": "Barisal",
  "Chittagong": "Chittagong",
  "Comilla": "Comilla",
  "Dhaka": "Dhaka",
  "Dinajpur": "Dinajpur",
  "Jashore": "Jashore",
  "Mymensingh": "Mymensingh",
  "Rajshahi": "Rajshahi",
  "Sylhet ": "Sylhet",
  "Technical ": "Technical",
  "Madrasah": "Madrasah",
}
export const boardData2 = [
  "Barisal",
  "Chittagong",
  "Comilla",
  "Dhaka",
  "Dinajpur",
  "Jashore",
  "Mymensingh",
  "Rajshahi",
  "Sylhet",
  "Technical",
  "Madrasah",
]

export const examNameData2 = [
  "SSC",
  "Dakhil",
  "Vocational ",
  "HSC",
  "Alim",
  "Polytechnic",
  "Others",
]
export const examNameData3 = [
  "SSC/Equivalent",
  "HSC/Equivalent",
  "BS/Equivalent",
  "MS//Equivalent",
]

export const graduateProgrameData = {
  "MS": "MS",
  "PhD": "PhD",
}

export const termOfAdmissionData = {
  "Summer": "Summer",
  "Autumn": "Autumn",
  "Winter": "Winter",
}
export const freedomFigherData = {
  "Yes": "Yes",
  "No": "No"
}

function lastAcademicYearFunc() {
  let years = {}; // Initialize an empty object to store the years
  for (let i = 2024; i >= 1910; i--) {
    years[i] = i; // Assign each year as a key-value pair to the object
  }
  console.log(years)
  return years; // Return the object containing all the years
}

function generateYearObject() {
  const currentYear = new Date().getFullYear();
  const yearObject = {};

  for (let year = currentYear; year >= 1910; year--) {
      yearObject[year] = year;
  }

  return yearObject;
}



export const lastAcademicYear =generateYearObject();

export const lastExam = {
  "SSC": "SSC",
  "Dakhil": "Dakhil",
  "Vocational":"Vocational",
  "HSC":"HSC",
  "Alim":"Alim",
  "JSC":"JSC",
  "Annual Exam":"Annual Exam",
  "Others":"Others",
}