import React, { useEffect, useState } from 'react';

import Footer from '../../Components/Footer';
import { Formik, Form, yupToFormErrors } from 'formik';
import { Checkbox, CircularProgress, Divider, List, ListItem, ListItemIcon, ListItemText, Snackbar, Step, StepButton, StepIcon, StepLabel, Stepper, TextField as Upload } from '@material-ui/core';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import Textfield from '../../Components/FormsUI/Textfield';
import Viewfield from '../../Components/FormsUI/Viewfield';
import SelectDistrict from '../../Components/FormsUI/SelectDistrict';
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import Select from '../../Components/FormsUI/Select';
import DatePicker from '../../Components/FormsUI/DataTimePicker';
import { fatheroccupationdata, motheroccupationdata, autismData, bloodData, genederData, quotaData2, religionData, examNameData, gradeData, boardData, examGroupData, medium, lastAcademicYear, lastExam } from '../../data/data';
import { Link, useHistory } from "react-router-dom";
import useReactRouter from 'use-react-router';
import { Button as Submit } from '@material-ui/core';
import { Button as Buttonh } from '@material-ui/core';
import { green, purple } from "@material-ui/core/colors";
import { TextField as TextF, MenuItem } from '@material-ui/core';
import HeaderMenu from '../../Components/Header/headeradmission';
import AdmissionMenu from '../../Components/Header/alumnimenu';



const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(8),
  },
  root: {
    color: green[900],
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)" // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C4C4C4" // customized
      }
    }
  }
}));




const AlumniForm = () => {
  const { match } = useReactRouter();
  const [instInfo, setinstInfo] = useState({});
  const [alumniData, setalumniData] = useState({});
  const [classData, setClassData] = useState([]);
  const [classDataId, setClassDataID] = useState(undefined);



  useEffect(() => {
    const fetchData = async () => {
      let res = await fetch(`${process.env.REACT_APP_API_ROOT}/public/online-applicantion/institute-info/${match.params.id}`)
      let data = await res.json()
      setinstInfo(data?.item)
      let alumni = await fetch(`${process.env.REACT_APP_API_ROOT}/public/alumni/configuration/view?instituteId=${match.params.id}`)
      let alumnidata = await alumni.json()
      setalumniData(alumnidata?.item)
      let classd = await fetch(`${process.env.REACT_APP_API_ROOT}/public/class/list/by/instituteId?instituteId=${match.params.id}`)
      let classdx = await classd.json()
      setClassData(classdx?.item)
    }
    fetchData();
  }, []);

  async function handleClassData(evt) {
    console.log(evt.target.value)
    setClassDataID(evt.target.value)
  }


  const [info, setInfo] = useState({});

  useEffect(() => {
    let infox = JSON.parse(localStorage.getItem('studentinfo'))
    if (infox === null) {
      setInfo({})
    } else {
      setInfo(infox)
    }
  }, [])



  const history = useHistory();

  if (info === null) {
    history.push("/");
  }

  const INITIAL_FORM_STATE = {
    applicantName: '',
  };



  const FORM_VALIDATION = Yup.object().shape({
    applicantName: Yup.string()
      .required('Required'),
    fatherName: Yup.string()
      .required('Required'),
    religion: Yup.string()
      .required('Required'),
    gender: Yup.string()
      .required('Required'),
    motherName: Yup.string()
      .required('Required'),
    mailingAddress: Yup.string(),
    permanentAddress: Yup.string(),

  });



  const classes = useStyles();

  const [payId, setpayId] = useState();
  const [successPay, setsuccessPay] = useState(false);
  const [step, setStep] = useState(1);


  const [districtList, setdistrictList] = useState([]);
  const [thanaList, setPresentthanaList] = useState([]);
  const [presentThanaId, setPresentThanaId] = useState('');
  const [presentDisId, setPresentDisId] = useState('');

  const [imageFileContent, setimageFileContent] = useState();
  const [imageFileSave, setimageFileSave] = useState('');
  const [imageFileName, setimageFileName] = useState(undefined);



  function clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch (ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }
  const imageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file?.size > 600000) {
      // file.target.value = null;
      clearFileInput(document.getElementById("imgfile"));
      setimageFileSave(false);
      setimageFileContent('');
      setimageFileName(undefined);
      alert("Image size should be less than 600KB");
      return;
    };
    if (reader !== undefined && file !== undefined) {
      setimageFileSave(true);

      reader.onloadend = () => {
        setimageFileName(file.name)
        setimageFileContent(reader.result.split(',')[1])
      }
      reader.readAsDataURL(file);
    }
  };


  const validateOthers = () => {
    // console.log('sss')
  }


  useEffect(() => {
    async function fetchMyAPI() {
      let disReponse = await fetch('https://api.shebashikkha.com/location/info/district-list')
      disReponse = await disReponse.json()
      //  console.log(disReponse)
      setdistrictList(disReponse?.item)
    }

    async function fetchThana() {
      if (info.districtId != null) {
        let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${info.districtId}`)
        thanaReponse = await thanaReponse.json()
        setPresentthanaList(thanaReponse?.item)
      }
    }
    fetchMyAPI();
    fetchThana();

  }, []);

  async function handlePresentDistrict(evt) {
    setPresentDisId(evt.target.value)
    let thanaReponse = await fetch(`https://api.shebashikkha.com/location/info/thana-list/by/district-id?districtId=${evt.target.value}`)
    thanaReponse = await thanaReponse.json()
    setPresentthanaList(thanaReponse?.item)
  }

  async function handlePresentThana(evt) {
    setPresentThanaId(evt.target.value)
    console.log(evt.target.value)
  }


  async function paymentPage() {
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ROOT}/public/get/alumni-application-payment/spg/session-id?alumniId=${payId}&instituteId=${parseInt(match.params.id)}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        // body: JSON.stringify(submit)
      });
      if (rawResponse.status === 201 || rawResponse.status === 200) {
        const content = await rawResponse.json();
        if (content?.messageType === 1) {
          window.open('https://api.shebashikkha.com/public/goto/alumni-application-payment/portal?sessiontoken=' + content?.item);
        } else {
          alert(content?.message);
        }

      } else {
        alert("Something went wrong");

      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
    }

  }




  async function nextData(values) {

    let postData = {
      "applicantImageFileContent": imageFileContent === undefined ? "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" : imageFileContent,
      "applicantImageFileSave": imageFileSave,
      "applicantName": values.applicantName,
      "numberOfGuest": values.numberOfGuest,
      "dob": values.dateOfBirth,
      "gender": values.gender,
      "religion": values.religion,
      "contactNo": values.contactNo,
      "fatherName": values.fatherName,
      "motherName": values.motherName,
      "mailingAddress": values.mailingAddress,
      "email": values.email,
      "lastExam": values.lastExam,
      "latestAcademicYear": values.latestAcademicYear,
      "latestExamYear": values.latestExamYear,
      "instituteId": match.params.id,
      "presentThanaId": presentThanaId,
      "classId": classDataId,
    }
    //console.log(postData)
    finalSubmit(postData);
  };


  const [loading, setLoading] = React.useState(false);
  async function finalSubmit(data) {
    setLoading(true);
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ROOT}/public/alumni/student/save`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (rawResponse.status === 201 || rawResponse.status === 200) {
        const content = await rawResponse.json();
        // console.log(content);
        setLoading(false);
        if (content.messageType === 1) {
          setsuccessPay(true);
          setStep(4);
          setpayId(content.item)
        }
      } else {
        alert("Something went wrong");
        setLoading(false);
      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
    }

  }



  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderMenu details={{ instituteName: instInfo?.institutename, address: instInfo?.instituteaddress, image: instInfo?.imageLink }} />
      </Grid>

      <Grid item xs={12} style={{ background: "#fff", boxShadow: "rgb(216 216 216) 0px 0px 15px 0px", padding: "15px" }}>

        <Divider />
        <AdmissionMenu />
        {!successPay && step === 1 &&
          <div className={classes.formWrapper}>

            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={values => {
                nextData(values);
              }}
            >

              {(formikProps) => {
                const { values, handleChange } = formikProps;
                return (
                  <Form>

                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Typography style={{ fontSize: "24px", textAlign: "center", marginBottom: "10px", color: "#f68b1f", fontWeight: "500" }}>
                          Alumni Occasion Application
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Viewfield
                          label="Application Fees"
                          name={alumniData?.applicationFee}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Viewfield
                          label="Service Charge"
                          name={alumniData?.serviceCharge}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Viewfield
                          label="Per Guest"
                          name={(alumniData?.perGuestAmount)}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 0 }}>
                        <Typography>
                          Basic Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="applicantName"
                          label="Applicant Name"

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="fatherName"
                          label="Father's Name"

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="motherName"
                          label="Mother's Name"

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <DatePicker views={['year', 'month', 'day']}
                          name="dateOfBirth"
                          label="Date Of Birth"

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="gender"
                          label="Gender"
                          options={genederData}

                        />
                      </Grid>


                      <Grid item xs={12} sm={4}>
                        <Select
                          name="religion"
                          label="Religion"
                          options={religionData}

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextF value={classDataId} required select={true} variant="outlined" fullWidth label="Last Class" onChange={(e) => {
                          handleClassData(e);
                        }} >
                          {classData?.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.id}>
                                {item.name}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="latestAcademicYear"
                          label="Last Class Academic Year"
                          options={lastAcademicYear}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="lastExam"
                          label="Last Exam"
                          options={lastExam}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Select
                          name="latestExamYear"
                          label="Last Exam Year"
                          options={lastAcademicYear}

                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield inputProps={{ maxLength: 20 }}
                          name="numberOfGuest"
                          label="Number of Guest"
                          type="number"
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="contactNo"
                          label="Contact Number"
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="email"
                          label="Email"
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Upload id="imgfile" style={{ width: "100%" }} classes={{ root: classes.root }} variant="outlined" label="Photo" focused={imageFileSave == '' && true} name="file" type="file" accept="image/*" onChange={(e) => {
                          imageUpload(e)

                        }} />
                      </Grid>


                      <Grid item xs={12} sm={4}>
                        <TextF value={presentDisId} required select={true} variant="outlined" fullWidth label="District" onChange={(e) => {
                          handlePresentDistrict(e);
                        }} >
                          {districtList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.districtId}>
                                {item.districtName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextF value={presentThanaId} required select={true} variant="outlined" fullWidth label="Thana" onChange={(e) => {
                          handlePresentThana(e);
                        }} >
                          {thanaList.map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item.thanaId}>
                                {item.thanaName}
                              </MenuItem>
                            )
                          })}
                        </TextF>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Textfield
                          name="mailingAddress"
                          label="Mailing Address"
                        //
                        />
                      </Grid>


                      <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <Submit type="submit" style={{ float: "right", backgroundColor: "#f68b1f" }} color='primary' variant="contained" onClick={() => validateOthers()} >
                          Submit
                        </Submit>

                      </Grid>
                    </Grid>

                  </Form>
                );
              }}
            </Formik>

          </div>
        }


        {successPay && step === 4 &&
          <>
            <p className='MuiTypography-root MuiTypography-h5' style={{ color: "Orange", marginTop: 40, textAlign: "center" }}>Applicant Info Successfully Saved.</p>
            <p className='MuiTypography-root MuiTypography-h6' style={{ marginBottom: 20, textAlign: "center" }}>Your Application ID is <strong>{payId}</strong>.</p>
            <p className='MuiTypography-root MuiTypography-h6' style={{ marginTop: 0, textAlign: "center" }}><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => window.open(
              `${process.env.REACT_APP_API_ROOT}/public/download/alumni/student/confirmation?instituteId=${match.params.id}&applicantId=${payId}`,
              '_blank' // <- This is what makes it open in a new window.
            )}><u>Click Here</u></span> to download your form.</p>
           
            {/* <p className='MuiTypography-root MuiTypography-h6' style={{ marginTop: 0, textAlign: "center" }}><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => paymentPage()} // <- This is what makes it open in a new window. */}
            {/* ><u>Click Here</u></span> to make payment</p> */}


          </>
        }

      </Grid>
    </Grid>
  );
};

export default AlumniForm;
